import _objectSpread from "/Users/kai/Desktop/\u8BD7\u69CA/service/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es7.object.values";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { on, off } from "element-ui/src/utils/dom";
import { rafThrottle, isFirefox } from "element-ui/src/utils/util";
var Mode = {
  CONTAIN: {
    name: "contain",
    icon: "el-icon-full-screen"
  },
  ORIGINAL: {
    name: "original",
    icon: "el-icon-c-scale-to-original"
  }
};
var mousewheelEventName = isFirefox() ? "DOMMouseScroll" : "mousewheel";
export default {
  name: "elImageViewer",
  props: {
    urlList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    zIndex: {
      type: Number,
      default: 2000
    },
    onSwitch: {
      type: Function,
      default: function _default() {}
    },
    onClose: {
      type: Function,
      default: function _default() {}
    },
    initialIndex: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      index: this.initialIndex,
      isShow: false,
      infinite: true,
      loading: false,
      mode: Mode.CONTAIN,
      transform: {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false
      }
    };
  },
  computed: {
    isSingle: function isSingle() {
      return this.urlList.length <= 1;
    },
    isFirst: function isFirst() {
      return this.index === 0;
    },
    isLast: function isLast() {
      return this.index === this.urlList.length - 1;
    },
    currentImg: function currentImg() {
      return this.urlList[this.index];
    },
    imgStyle: function imgStyle() {
      var _this$transform = this.transform,
        scale = _this$transform.scale,
        deg = _this$transform.deg,
        offsetX = _this$transform.offsetX,
        offsetY = _this$transform.offsetY,
        enableTransition = _this$transform.enableTransition;
      var style = {
        transform: "scale(".concat(scale, ") rotate(").concat(deg, "deg)"),
        transition: enableTransition ? "transform .3s" : "",
        "margin-left": "".concat(offsetX, "px"),
        "margin-top": "".concat(offsetY, "px")
      };
      if (this.mode === Mode.CONTAIN) {
        style.maxWidth = style.maxHeight = "100%";
      }
      return style;
    }
  },
  watch: {
    index: {
      handler: function handler(val) {
        this.reset();
        this.onSwitch(val);
      }
    },
    currentImg: function currentImg(val) {
      var _this = this;
      this.$nextTick(function (_) {
        var $img = _this.$refs.img[0];
        if (!$img.complete) {
          _this.loading = true;
        }
      });
    }
  },
  methods: {
    hide: function hide() {
      this.deviceSupportUninstall();
      this.$emit("close");
    },
    deviceSupportInstall: function deviceSupportInstall() {
      var _this2 = this;
      this._keyDownHandler = rafThrottle(function (e) {
        var keyCode = e.keyCode;
        switch (keyCode) {
          // ESC
          case 27:
            _this2.hide();
            break;
          // SPACE
          case 32:
            _this2.toggleMode();
            break;
          // LEFT_ARROW
          case 37:
            _this2.prev();
            break;
          // UP_ARROW
          case 38:
            _this2.handleActions("zoomIn");
            break;
          // RIGHT_ARROW
          case 39:
            _this2.next();
            break;
          // DOWN_ARROW
          case 40:
            _this2.handleActions("zoomOut");
            break;
        }
      });
      this._mouseWheelHandler = rafThrottle(function (e) {
        var delta = e.wheelDelta ? e.wheelDelta : -e.detail;
        if (delta > 0) {
          _this2.handleActions("zoomIn", {
            zoomRate: 0.015,
            enableTransition: false
          });
        } else {
          _this2.handleActions("zoomOut", {
            zoomRate: 0.015,
            enableTransition: false
          });
        }
      });
      on(document, "keydown", this._keyDownHandler);
      on(document, mousewheelEventName, this._mouseWheelHandler);
    },
    deviceSupportUninstall: function deviceSupportUninstall() {
      off(document, "keydown", this._keyDownHandler);
      off(document, mousewheelEventName, this._mouseWheelHandler);
      this._keyDownHandler = null;
      this._mouseWheelHandler = null;
    },
    handleImgLoad: function handleImgLoad(e) {
      this.loading = false;
    },
    handleImgError: function handleImgError(e) {
      this.loading = false;
      e.target.alt = "加载失败";
    },
    handleMouseDown: function handleMouseDown(e) {
      var _this3 = this;
      if (this.loading || e.button !== 0) return;
      var _this$transform2 = this.transform,
        offsetX = _this$transform2.offsetX,
        offsetY = _this$transform2.offsetY;
      var startX = e.pageX;
      var startY = e.pageY;
      this._dragHandler = rafThrottle(function (ev) {
        _this3.transform.offsetX = offsetX + ev.pageX - startX;
        _this3.transform.offsetY = offsetY + ev.pageY - startY;
      });
      on(document, "mousemove", this._dragHandler);
      on(document, "mouseup", function (ev) {
        off(document, "mousemove", _this3._dragHandler);
      });
      e.preventDefault();
    },
    reset: function reset() {
      this.transform = {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false
      };
    },
    toggleMode: function toggleMode() {
      if (this.loading) return;
      var modeNames = Object.keys(Mode);
      var modeValues = Object.values(Mode);
      var index = modeValues.indexOf(this.mode);
      var nextIndex = (index + 1) % modeNames.length;
      this.mode = Mode[modeNames[nextIndex]];
      this.reset();
    },
    prev: function prev() {
      if (this.isFirst && !this.infinite) return;
      var len = this.urlList.length;
      this.index = (this.index - 1 + len) % len;
    },
    next: function next() {
      if (this.isLast && !this.infinite) return;
      var len = this.urlList.length;
      this.index = (this.index + 1) % len;
    },
    handleActions: function handleActions(action) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      if (this.loading) return;
      var _zoomRate$rotateDeg$e = _objectSpread({
          zoomRate: 0.2,
          rotateDeg: 90,
          enableTransition: true
        }, options),
        zoomRate = _zoomRate$rotateDeg$e.zoomRate,
        rotateDeg = _zoomRate$rotateDeg$e.rotateDeg,
        enableTransition = _zoomRate$rotateDeg$e.enableTransition;
      var transform = this.transform;
      switch (action) {
        case "zoomOut":
          if (transform.scale > 0.2) {
            transform.scale = parseFloat((transform.scale - zoomRate).toFixed(3));
          }
          break;
        case "zoomIn":
          transform.scale = parseFloat((transform.scale + zoomRate).toFixed(3));
          break;
        case "clocelise":
          transform.deg += rotateDeg;
          break;
        case "anticlocelise":
          transform.deg -= rotateDeg;
          break;
      }
      transform.enableTransition = enableTransition;
    },
    // 下载当前图片
    download: function download() {
      var url = this.urlList[this.initialIndex];
      var _date = new Date();
      this.downloadFile(url, "xxx ".concat(_date.getFullYear(), "-").concat(_date.getMonth() + 1, "-").concat(_date.getDate()));
    },
    downloadFile: function downloadFile(content, fileName) {
      fetch(content).then(function (res) {
        return res.blob().then(function (blob) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(blob);
          // var filename = "myfile.zip";
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        });
      });

      // //下载base64图片
      // var base64ToBlob = function (code) {
      //   debugger
      //   let parts = code.split(";base64,");
      //   let contentType = parts[0].split(":")[1];
      //   let raw = window.atob(parts[1]);
      //   let rawLength = raw.length;
      //   let uInt8Array = new Uint8Array(rawLength);
      //   for (let i = 0; i < rawLength; ++i) {
      //     uInt8Array[i] = raw.charCodeAt(i);
      //   }
      //   return new Blob([uInt8Array], {
      //     type: contentType,
      //   });
      // };
      // let aLink = document.createElement("a");
      // let blob = base64ToBlob(content); //new Blob([content]);
      // let evt = document.createEvent("HTMLEvents");
      // evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      // aLink.download = fileName;
      // aLink.href = URL.createObjectURL(blob);
      // aLink.click();
    }
  },
  mounted: function mounted() {
    this.deviceSupportInstall();
    // add tabindex then wrapper can be focusable via Javascript
    // focus wrapper so arrow key can't cause inner scroll behavior underneath
    this.$refs["el-image-viewer__wrapper"].focus();
  }
};