import request from "./request";

/**
 * 获取左侧客服聊天用户列表
 */
export function record(params) {
  return request.get('/service/page', params);
}
export function history(uid, params) {
  return request.get('/service/history/list/' + uid, params);
}
export function merchantInfo() {
  return request.get('/service/mer/user');
}
export function adminInfo() {
  return request.get('/service/user');
}
export function changeMark(uid, mark) {
  return request.post('/user/mark/' + uid, {
    mark: mark
  });
}
/**
 * @description 验证码
 */
export function captchaApi() {
  return request.get("/publicly/validate/code/get");
}
/**
 * @description 登录
 */
export function login(data) {
  // return request.post(`login`, data)
  return request.post("admin/platform/service/login", data);
}
/**
 * @description 扫码登录
 */
export function scanLogin() {
  return request.post("login/scan");
}
/**
 * @description 扫码登录检查
 */
export function scanLoginCheck(key) {
  return request.post("login/scan/check", {
    key: key
  });
}

/**
 * @description 退出登录
 */
export function logout() {
  return request.post("/service/logout");
}

/**
 * @description 订单详情
 */
export function orderInfo(orderNo) {
  return request.get("/service/order/detail/".concat(orderNo));
}
/**
 * @description 退款单详情
 */
export function refundOrderInfo(id) {
  return request.get("/service/refund/detail/".concat(id));
}
/**
 * @description 退款单----详情
 */
export function refundDetail(id) {
  return request.get("/service/refund/detail/".concat(id));
}
/**
 * @description 订单物流
 */
export function getExpress(id) {
  return request.get("/service/get/".concat(id, "/logistics/info"));
}
export function invoiceList(orderNo) {
  return request.get("/service/".concat(orderNo, "/invoice/list"));
}
/**
 * @description 退款单物流
 */
export function refundorderExpressApi(id) {
  return request.get("refund_express/".concat(id));
}
/**
 * @description 退款单记录
 */
export function refundorderLogApi(id, data) {
  return request.get("order_status/".concat(id), data);
}
/**
 * @description 商品记录
 */
export function productDetails(id) {
  return request.get("/service/product/detail/".concat(id));
}

/**
 * @description 退出登录
 */
export function config() {
  return request.get("config");
}

/**
 * 版权信息
 * @returns
 */
export function version() {
  return request.get("../api/version");
}
/**
 * 客服聊天--撤回消息
 * @param object data
 *
 */
export function chatReverstApi(id) {
  return request.post("recall/".concat(id));
}
/**
 * @description 上传图片
 */
export function uploadFile(data) {
  return request.post("service/upload/file", data);
}
/**
 * @description 用户订单列表
 */
export function getServiceOrderList(data) {
  return request.post("/service/order/list", data);
}
/**
 * @description 用户售后订单列表
 */
export function getServiceRefundOrderList(data) {
  return request.get("/service/refund/list", data);
}